const hotWordApi = {
    //获取热搜词列表
    hotList: '/hotword/list',
    //删除热搜词
    hotDelete: '/hotword/delete',
    //新增热搜词
    hotAdd: '/hotword/add',
    //编辑热搜词
    hotEdit: '/hotword/edit',
    //批量删除热搜词
    hotbatchDelete:'/hotword/batchDelete',
    //根据id获取热搜词
    hotIdWord:'/hotword/getById',
    // 开关热搜词
    hotEnabled:'/hotword/enabled'
}


export default hotWordApi