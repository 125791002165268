import request from "@/utils/request";
import hotWordApi from '@/const/apiUrl/hotword';
import method from "@/const/request/requestMethod";
import requestType from "@/const/request/requestType";
import contentType from "@/const/request/requestHeader";

// 获取热搜词列表
export function getHotList(params) {
    return request({
        url: hotWordApi.hotList,
        method: method.GET,
        data: params
    })
}
// 删除热搜词
export function hotDelete(params) {
    return request({
        url: hotWordApi.hotDelete,
        method: method.GET,
        data: params,
        requestType: requestType.DELETE
    })
}

//新增热搜词
export function hotAdd(params) {
    return request({
        url: hotWordApi.hotAdd,
        method: method.POST,
        data: params,
        requestType: requestType.ADD,
    })
}

//编辑热搜词
export function hotEdit(params) {
    return request({
        url: hotWordApi.hotEdit,
        method: method.POST,
        data: params,
        requestType: requestType.EDIT
    })
}

// 批量删除热搜词
export function hotbatchDelete(params) {
    return request({
        url: hotWordApi.hotbatchDelete,
        method: method.GET,
        data: params,
        requestType: requestType.DELETE
    })
}

// 根据id获取热搜词
export function hotIdWord(params) {
    return request({
        url: hotWordApi.hotIdWord,
        method: method.GET,
        data: params
    })
}

// 开关热搜词
export function hotEnabled(params) {
    return request({
        url: hotWordApi.hotEnabled,
        method: method.GET,
        data: params,
        requestType: requestType.EDIT,
    })
}
