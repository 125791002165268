<template>
  <!-- 角色管理 -->
  <div class="container" style="margin-top: 10px;">
      <div class="dataOper">
        <div class="OperInput">
          <div class="head">
          <div class="search_box">
            <div class="search_main">
              <div class="status1">
                <router-link to="/hotword/addHotword">
                  <div class="addBtn" style="width:110px">
                    <img src="../../assets/images/add.png" style="margin-right: 5px;width: 13px;">添加
                  </div>
                </router-link>
                <div style="margin-left: 10px;margin-right:20px;width:110px;"  class="deleteBtnAll" @click="deleteAll" id="deleteAll">
                  <i class="el-icon-delete" style="margin-right: 5px"></i>批量删除
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div>类型</div>
                <div>
                  <el-select style="width:260px;height:36px;" v-model="queryinfo.category" placeholder="请选择类型">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status2">
                <div style="margin-right: 10px;">搜索</div>
                <div>
                  <el-input style="width:260px;height:36px;margin-right: 20px;" placeholder="请输入热搜词名称"
                    v-model="queryinfo.phrase" clearable>
                  </el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status1">
                <div class="demandBtn" @click="search">
                  <img src="../../assets/images/search.png" alt="" /> 查询
                </div>
                <div class="resetBtn" @click="reset">
                  <img src="../../assets/images/reset.png" alt="" />重置
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="body">
      <div class="dataTable">
        <el-table   style="font-family: PingFangRoutine;" :data="tableData" :header-cell-style="tableHeaderColor"
          @selection-change="handleSelectionChange">
          <el-table-column align="center" type="selection" width="55" label="全选">
          </el-table-column>s
          <el-table-column align="center" prop="height" label="权重"> </el-table-column>
          <el-table-column align="center" prop="category" label="热搜词类型">
            <template slot-scope="scope">
              {{ scope.row.category ? "搜索框默认词" : "热搜词" }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="热搜词名称">
            <template slot-scope="scope">
              {{ scope.row.phrase }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="enabled" label="是否显示">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.enabled" active-color="rgb(64,158,255)" inactive-color="rgb(138,138,138)"
                @change="changeComposeStatus(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="jumpUrl" label="链接地址"> </el-table-column>
          <el-table-column align="center" label="操作" >
            <template v-slot="scope">
              <div class="operating">
                <div class="edit" @click="roleEdit(scope.row.id)">
                  <img style="width: 16px;height: 16px;" src="../../assets/images/edit.png"/>
                </div>
                <div class="delete" @click="roleDel(scope.row.id)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-size="10"
            layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
  </div>
</template>

<script>
  import {
    getHotList,
    hotDelete,
    hotEnabled,
    hotbatchDelete,
  } from "@/api/hotword";
  import "../../font-style/font.css";
  export default {
    name: "addHotword",
    data() {
      return {
        queryinfo: {
          category: "", //类型
          phrase: "", // 关键字
          currPage: "", //分页
        },
        options: [{
            name: "热搜词",
            id: 0
          },
          {
            name: "搜索框默认词",
            id: 1
          },
        ],
        tableData: [],
        total: null,
        conceal: true,
        arr:[]
      };
    },
    activated() {
      this.list();
    },
    methods: {
      //获取热搜词列表
      list() {
        getHotList(this.queryinfo).then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.total * 1;
        });
      },

      // 分页
      handleCurrentChange(val) {
        this.queryinfo.currPage = val;
        this.list();
      },

      // table表头样式
      tableHeaderColor({ rowIndex }) {
        if (rowIndex === 0) {
          return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
        }
      },

      handleSelectionChange(val) {
        this.arr = val.map((item) => {
          return item.id;
        });
      },

      //搜索
      search() {
        this.queryinfo.currPage = ""
        this.total = 0
        this.list();
      },

      // 重置
      reset() {
        (this.queryinfo.category = ""), (this.queryinfo.phrase = "");
        this.queryinfo.currPage = ""
        this.total = 0
        this.list();
      },

      //删除
      async roleDel(id) {
        if (!(await this._box())) return;
        hotDelete({
          id
        }).then(() => {
          let totalPage = Math.ceil((this.total - 1) / 10);
          let currentPage =
            this.queryinfo.currPage > totalPage ?
            totalPage :
            this.queryinfo.currPage;
          this.queryinfo.currPage = currentPage < 1 ? 1 : currentPage;
          this.list();
        });
      },

      //批量删除
      async deleteAll() {
        if (this.arr.length <= 0) {
          this.$confirm('请选择要操作的数据', '提示', {
            type: 'info',
          })
        }
        if (this.arr.length > 0) {
          if (!(await this._box())) return;
          hotbatchDelete({
            ids: this.arr.join(',')
          }).then(() => {
            this.list()
          })
        }
      },

      //显示隐藏
      changeComposeStatus(val) {
        hotEnabled({
          id: val.id
        }).then(() => {
        });
      },

      //编辑
      roleEdit(id) {
        this.$router.push({
          path: "/hotword/edit",
          query: {
            roleid: id
          }
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  @import "../../assets/css/common.less";
  .head{
    padding: 0px;
    padding-left: 20px;
    padding-top: 18px;
    padding-bottom: 17px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  }
  /deep/ .search_main{
    margin-top: 0;
  }
 .specialColor {
   width: 60px;
   height: 30px;
   background: #bbbaba;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 #picture {
   width: 130px;
   height: 50px;

   img {
     width: 100%;
     height: 100%;
   }
 }
 /deep/ .cell {
   text-align: center;
 }
 .OperInputs {
   display: flex;
   #deleteAll {
     width: 120px;
     height: 36px;
   }
 }
 .search_mains {
   display: inline-block;
 }
 /deep/.el-input__inner {
   height: 36px;
 }
 .Inquire {
   width: 60px;
   height: 36px;
   background: #e789fb;
   border-radius: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
   font-family: PingFang SC;
   font-weight: 400;
   color: #fafafa;
   margin-left: 20px;
   cursor: pointer;
 }
 .Inquire>img {
   height: 14px;
   margin-right: 5px;
 }
 .reset {
   width: 60px;
   height: 36px;
   background: #fe8496;
   border-radius: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
   font-family: PingFang SC;
   font-weight: 400;
   color: #fafafa;
   margin-left: 10px;
   cursor: pointer;
 }
 .reset:hover {
   opacity: 0.7;
   cursor: pointer;
 }
 .reset>img {
   height: 14px;
   margin-right: 5px;
 }
 .status1 {
   height: 36px;
   display: flex;
   align-items: center;
   font-size: 14px;
   font-weight: 400;
   color: #2c2c2c;
 }
 .status2 {
   height: 36px;
   display: flex;
   align-items: center;
   font-size: 14px;
   font-family: PingFang;
   font-weight: 400;
   color: #2c2c2c;
 }
 /deep/ .el-input__icon {
     height: 100%;
     width: 25px;
     text-align: center;
     transition: all .3s;
     line-height: 36px;
 }
</style>
